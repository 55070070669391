import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import LeftArrow from '../img/ArrowLeft.svg'
import RightArrow from '../img/ArrowRight.svg'
import { MIN_LARGER_DISPLAY_MQ } from '../utils/presets'

const LinkBase = styled.a({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10000,
  position: 'absolute',
  width: `140px`,
  [MIN_LARGER_DISPLAY_MQ]: {
    width: '20vw',
  },
  top: '15vh',
  bottom: '15vh',
  outline: 'none',
  cursor: 'pointer',
  'img': {
    transition: `300ms transform ease-in-out`,
    transform: `translateX(0)`
  },
  opacity: 1,
}, ({direction}) => {
  let x
  if (direction === 'prev') {
    x = `-10px`
  } else {
    x = `10px`
  }
  return {
    ':hover img': {
      transform: `translateX(${x})`
    }
  }
})

const Arrow = styled.div({
  width: '2rem',
  height: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: `16px`,
})
/*
const Icon = styled.img({
  width: 'auto',
  height: '18px'
})*/

const PrevLink = styled(LinkBase)({ left: 0 })

const NextLink = styled(LinkBase)({ right: 0 })

const ScreenReaderText = styled.span({
  position: 'absolute',
  left: '-999em'
})

const Arrows = ({ handlePrevClick, handleNextClick }) => {
  return (
    <React.Fragment>
      <PrevLink
        onClick={ handlePrevClick }
        role={ 'link' }
        tabIndex={ '0' }
        visible={true}
        direction={'prev'}
      >
        <Arrow>
          {/*<Icon src={LeftArrow} alt='Prev' />*/}
          <img src={LeftArrow} alt='Prev' />
        </Arrow>
        <ScreenReaderText>{ 'Prev' }</ScreenReaderText>
      </PrevLink>
      <NextLink
        onClick={ handleNextClick }
        role={ 'link' }
        tabIndex={ '0' }
        visible={true}
        direction={'next'}
      >
        <Arrow>
          {/*<Icon src={RightArrow} alt='Next' />*/}
          <img src={RightArrow} alt='Next' />
        </Arrow>
        <ScreenReaderText>{ 'Next' }</ScreenReaderText>
      </NextLink>
    </React.Fragment>
  )
}

Arrows.propTypes = {
  handleNextClick: PropTypes.func.isRequired,
  handlePrevClick: PropTypes.func.isRequired
}

export default Arrows
