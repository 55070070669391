import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'

const CenteredImage = ({ imageInfo, loading, isPortrait }) => {
  let imageStyle = { width: `70vw`, margin: `15vh auto` }
  const { alt = '', childImageSharp, image } = imageInfo

  if (!!image && !!image.childImageSharp) {

    if (!!image.childImageSharp.fluid) {
      if (!isPortrait) { // if landscape
        imageStyle.width = `calc(70vh * ${image.childImageSharp.fluid.aspectRatio})`;
      }
      return (
        <Img style={imageStyle} loading={loading} fluid={image.childImageSharp.fluid} alt={alt} />
      )
    }
  }

  if (!!childImageSharp) {
    return <Img style={imageStyle} loading={loading} fluid={childImageSharp.fluid} alt={alt} />
  }

  if (!!image && typeof image === 'string')
    return <img style={imageStyle} src={image} alt={alt} />

  return null
}

CenteredImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
}

export default CenteredImage
