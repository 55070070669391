import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import SwipeableViews from 'react-swipeable-views'
import { bindKeyboard } from 'react-swipeable-views-utils'
import CenteredImage from '../components/CenteredImage'
import FluidImage from '../components/FluidImage'
import Arrows from '../components/Arrows'
import mod from '../utils/mod'
import useHasMounted from '../hooks/useHasMounted'

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews)

const Wrapper = styled.main({
  position: 'relative',
  height: '100vh',
  '.react-swipeable-view-container': {
    height: '100%',
    '[data-swipeable="true"]': {
      display: `flex`,
      alignItems: `center`
    }
  },
})

export const IndexPageTemplate = ({
  images
}) => {

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  // view index
  const [currIdx, setCurrIdx] = useState(0)
  const [prevIdx, setPrevIdx] = useState(0)
  const [preloadIdx, setPreloadIdx] = useState([1, images.length > 0 ? images.length : 0])
  const handleChangeIndex = (currIdx, prevIdx) => {
    setCurrIdx(currIdx)
    setPrevIdx(prevIdx)
  }

  const preload = (idx) => {
    const count = images.length > 0 ? images.length : 0
    let nextIndex = idx
    let prevIndex = idx
    nextIndex += 1
    prevIndex -= 1
    if (count) {
      nextIndex = mod(nextIndex, count)
      prevIndex = mod(prevIndex, count)
    }
    setPreloadIdx([nextIndex, prevIndex])
  }

  // click handlers
  const handleNextClick = () => {
    const count = images.length > 0 ? images.length : 0
    const indexLatest = currIdx
    let viewIndex = indexLatest
    viewIndex += 1
    if (count) {
      viewIndex = mod(viewIndex, count)
    }
    setCurrIdx(viewIndex)
    setPrevIdx(indexLatest)
    preload(viewIndex)
  }

  const handlePrevClick = () => {
    const count = images.length > 0 ? images.length : 0
    const indexLatest = currIdx
    let viewIndex = indexLatest
    viewIndex -= 1
    if (count) {
      viewIndex = mod(viewIndex, count)
    }
    setCurrIdx(viewIndex)
    setPrevIdx(indexLatest)
    preload(viewIndex)
  }

  const renderDesktopView = () => images && images.length > 0 && (
    <React.Fragment>
      <BindKeyboardSwipeableViews
        index={ currIdx }
        onChangeIndex={ handleChangeIndex }
        enableMouseEvents
        style={{width: '100%', height: '100%'}}
      >
        { images.map((image, index) =>
          <CenteredImage
            key={index}
            imageInfo={image}
            loading={index === preloadIdx[0] || index === preloadIdx[1] || index === currIdx ? `eager` : `lazy`}
            isPortrait={isPortrait}
          />
          )
        }
      </BindKeyboardSwipeableViews>
      <Arrows
        handleNextClick={ handleNextClick }
        handlePrevClick={ handlePrevClick }
      />
    </React.Fragment>
  )

  const renderDeviceView = () => images && images.length > 0 && images.map((image, index) =>
    <FluidImage
      key={index}
      imageInfo={image}
    />
  )

  // fix for rehydration issues caused by conditional rendering
  // of isPortrait & isTabletOrMobile checks
  // needs to be placed after all hooks are called or we get a React error
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <Wrapper>
      { isTabletOrMobile ? renderDeviceView() : renderDesktopView() }
    </Wrapper>
  )
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <IndexPageTemplate images={frontmatter.images} />
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        images {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
