// https://joshwcomeau.com/react/the-perils-of-rehydration/
import { useState, useEffect } from 'react'

export default function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}
