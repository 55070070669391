import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'

const FluidImage = ({ imageInfo }) => {
  let imageStyle = {
    width: `96vw`,
    margin: `4vh auto`
  }
  const { alt = '', childImageSharp, image } = imageInfo

  if (!!image && !!image.childImageSharp && !!image.childImageSharp.fluid) {
    return (
      <Img
      style={{
        width: `96vw`,
        margin: `4vh auto`
      }}
      fluid={image.childImageSharp.fluid} alt={alt} />
    )
  }

  if (!!childImageSharp) {
    return <Img style={imageStyle} fluid={childImageSharp.fluid} alt={alt} />
  }

  if (!!image && typeof image === 'string')
    return <img style={imageStyle} src={image} alt={alt} />

  return null
}

FluidImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
}

export default FluidImage
